
.bg-hover:hover {
    background-color: rgba(255,255,255,0.1);
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
        display: none;
    }
}

.bounce {
    animation: bounce 2s;
    animation-iteration-count: 1;
}
.bounce-sm {
    animation: bounce-sm 1s;
    animation-iteration-count: 1;
}
@keyframes spin-slow {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.animate-spin-slow {
    animation: spin-slow 10s linear infinite;
}
  

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
}
@keyframes bounce-sm {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
    60% {
      transform: translateY(-2px);
    }
}

/*
    Scroller
*/
:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 120s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}
@media (prefers-color-scheme: dark) {
    :root {
        --color-text: papayawhip;
        --color-bg: navy;
        --color-bg-accent: #2626a0;
    }
}
    
* {
    box-sizing: border-box;
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(
        var(--mask-direction, to right),
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 5%,
        hsl(0 0% 0% / 1) 95%,
        hsl(0 0% 0% / 0)
    );
}
    
.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
}
    
@media (prefers-reduced-motion: reduce) {
    .marquee__group {
        animation-play-state: paused;
    }
}

.marquee--vertical {
    --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
    flex-direction: column;
}
    
.marquee--vertical .marquee__group {
    animation-name: scroll-y;
    animation: scroll-y 80s linear infinite;
}

.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -2s;
}

@keyframes scroll-x {
    from {
        transform: translateX(var(--scroll-start));
    }
    to {
        transform: translateX(var(--scroll-end));
    }
}

@keyframes scroll-y {
    from {
        transform: translateY(var(--scroll-start));
    }
    to {
        transform: translateY(var(--scroll-end));
    }
}

/* Parent wrapper */
.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    width: 100%;
    max-width: 100vw;
}

.wrapper--vertical {
    flex-direction: row;
    height: 80vh;
}

/*
    Animations
*/

.reveal{
    transform: translateY(150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-top{
    transform: translateY(-150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal-top.active {
    transform: translateX(0);
    opacity: 1;
}
.reveal-right{
    transform: translateX(150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal-right.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-left{
    transform: translateX(-150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal-left.active{
    transform: translateX(0);
    opacity: 1;
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
}
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
  
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

.flip-card-front {
    
}

.flip-card-back {
    transform: rotateY(180deg);
}

.landing-fadein{
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
     -ms-animation: fadein 3s; /* Internet Explorer */
      -o-animation: fadein 3s; /* Opera < 12.1 */
         animation: fadein 3s;
}

.loading-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.dot {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background-color: white; /* Change color to suit your theme */
    border-radius: 50%;
    animation: load-bounce 1.5s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes load-bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}


@media only screen and (max-width: 1000px) {
    
    .no-mobile {
        display: none !important;
    }
}